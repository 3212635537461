import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import CommonService from "../services/common.service";

const initialState = {
  isLoading: false,
  fixtureData: [],
};

export const getFixture = createAsyncThunk(
  "fixture/getFixture",
  async (selectedRoundId, thunkAPI) => {
    try {
      const data = await CommonService.getFixture(selectedRoundId);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInCompFixture = createAsyncThunk(
  "getInCompFixture",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getInCompFixture();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const FixtureSlice = createSlice({
  name: "fixture",
  initialState,
  extraReducers: {
    [getFixture.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getFixture.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.fixtureData = action.payload.data;
      state.roundDetails = action.payload.round_details;
      state.isVotingActivate = action.payload.isVotingActivate;
    },
    [getFixture.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getInCompFixture.fulfilled]: (state, action) => {
      state.inprogress_fixtures = action.payload.in_progress_fixtures;
      state.completed_fixtures = action.payload.completed_fixtures;
    },
  },
});

const { reducer } = FixtureSlice;
export default reducer;
