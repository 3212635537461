import { agent } from "../utils/agent";
const API_URL = process.env.REACT_APP_API_URL;

const getGameDetail = async (gameData = {}) => {
  const response = await agent.get(
    `${API_URL}getGameAccount?club_name=${gameData.name}&club_id=${gameData.club_id}`
  );
  return response.data;
};
const MyGameService = {
  getGameDetail,
};

export default MyGameService;
