import { agent } from "../utils/agent";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const getDashboardData = async () => {
  const response = await agent.get(API_URL + `dashboard`);
  return response.data;
};
const tradeInOut = async (tradeType, roundId = "") => {
  const response = await agent.get(
    API_URL + `tradeInOut?tradeType=${tradeType}&roundId=${roundId}`
  );
  return response.data;
};

const topPlayerOverAllData = async (tradeType) => {
  const response = await agent.get(API_URL + `topPlayerOverAllData`);
  return response.data;
};
const topPlayerRound = async (tradeType) => {
  const response = await agent.get(API_URL + `topPlayerRound`);
  return response.data;
};
const getTradingListing = async (tradeType) => {
  const response = await agent.get(API_URL + `getTradingListing`);
  return response.data;
};
const getGuestTradingListing = async (club_id) => {
  const response = await axios.get(
    API_URL + `getGuestTradingListing?club_id=${club_id}`
  );
  return response.data;
};

const DashboardService = {
  getDashboardData,
  tradeInOut,
  topPlayerOverAllData,
  topPlayerRound,
  getTradingListing,
  getGuestTradingListing,
};

export default DashboardService;
