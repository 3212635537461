import { agent } from "../utils/agent";

const API_URL = process.env.REACT_APP_API_URL;

const getHistory = async (clubId) => {
  const response = await agent.get(API_URL + `history`);
  return response.data;
};
const getCapVCapHistory = async (clubId) => {
  const response = await agent.get(API_URL + `getCapVCapHistory`);
  return response.data;
};
const getPowerHistory = async (clubId) => {
  const response = await agent.get(API_URL + `getPowerHistory`);
  return response.data;
};

const History = {
  getHistory,
  getCapVCapHistory,
  getPowerHistory,
};

export default History;
