import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import StatService from "../services/stat.service";

const initialState = {
  isLoading: false,
  filterIsLoading: false,
  statData: [],
};

export const getStat = createAsyncThunk(
  "stat/getStat",
  async ({ rd, team, currentPage, perPage }, thunkAPI) => {
    try {
      const data = await StatService.getStat(rd, currentPage, perPage);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStatFilter = createAsyncThunk(
  "stat/getStatFilter",
  async ({ rd, team }, thunkAPI) => {
    try {
      const data = await StatService.getStat(rd, team);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getRDPlayerPoint = createAsyncThunk(
  "stat/getRDPlayerPoint",
  async ({ playerId }, thunkAPI) => {
    try {
      const data = await StatService.getRDPlayerPoint(playerId);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const StatSlice = createSlice({
  name: "stat",
  initialState,
  extraReducers: {
    [getStat.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getStat.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.statData = action.payload.data?.result;
      state.position = action.payload.data?.position;
      state.teamList = action.payload.data?.teamList;
      state.otherData = action.payload.data?.other_data;
    },
    [getStat.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getStatFilter.pending]: (state, action) => {
      state.filterIsLoading = true;
    },
    [getStatFilter.fulfilled]: (state, action) => {
      state.filterIsLoading = false;
      state.statData = action.payload.data?.result;
      state.position = action.payload.data?.position;
    },
    [getStatFilter.rejected]: (state, action) => {
      state.filterIsLoading = true;
    },
    [getRDPlayerPoint.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.rdPlayerData = action.payload.data;
    },
    [getRDPlayerPoint.rejected]: (state, action) => {
      state.isLoading = true;
    },
  },
});

const { reducer } = StatSlice;
export default reducer;
