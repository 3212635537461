import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

export const register = createAsyncThunk(
  "auth/register",
  async ({ username, email, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(username, email, password);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const gameSignup = createAsyncThunk(
  "auth/gameSignup",
  async ({ formValue }, thunkAPI) => {
    try {
      const data = await AuthService.gameSignup(formValue);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const checkLoginUser = createAsyncThunk(
  "auth/checkLoginUser",
  async (thunkAPI) => {
    try {
      const data = await AuthService.checkLoginUser();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const userSignup = createAsyncThunk(
  "auth/userSignup",
  async ({ formValue }, thunkAPI) => {
    try {
      const data = await AuthService.userSignup(formValue);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  AuthService.logout();
});

const initialState = {
  isAuth: false,
  user: null,
  club_details: null,
  isLoading: false,
  checkLogin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isAuth = false;
    },
    [register.rejected]: (state, action) => {
      state.isAuth = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.user = action.payload.user;
      state.club_details = action.payload.club_details;
    },
    [login.rejected]: (state, action) => {
      state.isAuth = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isAuth = false;
      state.user = null;
    },
    [login.userSignup]: (state, action) => {
      state.user = action.payload.data;
    },
    [checkLoginUser.fulfilled]: (state, action) => {
      state.checkLogin = action.payload.is_login;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
