import React, { useState } from "react";
import moment from "moment";
import { Modal, Button, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardService from "../../services/dashboard.service";

const RoundModal = (props) => {
  const [activeTab, setActiveTab] = useState("round"); // Initial active tab
  const [lockoutList, setLockoutList] = useState([]); // Initial active tab

  const round_data = props.round_data;
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    if (tab === "trade") {
      if (lockoutList.length <= 0) {
        DashboardService.getTradingListing().then((res) => {
          setLockoutList(res.data);
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={props.visible}
        onHide={() => props.setVisible(false)}
        size="lg"
      >
        <Modal.Header>Rounds & Trade Windows</Modal.Header>
        <Modal.Body>
          <Tabs
            id="round-modal-tabs"
            activeKey={activeTab}
            variant="tabs"
            onSelect={handleTabSelect}
            style={{ maxHeight: "500px", overflowY: "auto" }}
          >
            <Tab
              eventKey="round"
              title="Rounds"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              {/* Content for the "Round" tab goes here */}
              <div className="pointsystem-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Round</th>
                      <th>Start Day</th>
                      <th>End Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {round_data &&
                      round_data.map((item, key) => (
                        <tr key={key}>
                          <td>{item.round}</td>
                          <td>{moment(item.start_date).format("D.MM.YYYY")}</td>
                          <td>{moment(item.end_date).format("D.MM.YYYY")}</td>
                          <td>{moment(item.start_date).format("h:mm A")}</td>
                          <td>{moment(item.end_date).format("h:mm A")}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab
              eventKey="trade"
              title="Trade Windows"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <div className="pointsystem-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Trade Window</th>
                      <th>Start Day</th>
                      <th>End Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lockoutList &&
                      lockoutList.map((item, key) => (
                        <tr key={key}>
                          <td>{++key}</td>
                          <td>
                            {moment(item.trade_start).format("D.MM.YYYY")}
                          </td>
                          <td>
                            {moment(item.trade_close).format("D.MM.YYYY")}
                          </td>
                          <td>{moment(item.trade_start).format("h:mm A")}</td>
                          <td>{moment(item.trade_close).format("h:mm A")}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div className="poweredby">
            Powered By
            <img src="/images/logo.png" alt="img" />
          </div>
          <div className="">
            <Link
              className="border-btn"
              onClick={() => props.setVisible(false)}
            >
              Close
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RoundModal;
