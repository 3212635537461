import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../Slice/auth";

const Header = (props) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(logout())
      .unwrap()
      .then((data) => {
        navigate(`/login`);
      });
  };

  const linksContainerRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("user"));
  const collapseNav = (e, url) => {
    e.preventDefault();
    // navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
    // navigate(url);
    window.location.href = url;
  };
  var checkLogin = localStorage.getItem("token");
  const loginAsAdmin = (userId) => {
    window.location.href =
      process.env.REACT_APP_ADMIN_URL + "admin-login?user_id=" + userId;
  };
  return (
    <header className={checkLogin ? "" : "before-login"}>
      <div className="container-fluid">
        <div className="header">
          <div className="logo">
            <figure>
              <Link to={"/"}>
                <img src="../images/logo.png" alt="logo" />
              </Link>
            </figure>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
