import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CommonService from "../services/common.service";
import { setMessage } from "./message";
const initialState = {
  favClub: [],
  clubDetail: [],
  totwData: [],
  articleData: [],
  prizeData: [],
  memberData: [],
  articleDetailData: [],
  isLoading: false,
  image_thumb_image_path: "",
  image_main_image_path: "",
  tradeHistoryData: {},
  gw_trade_in: {},
  gw_trade_out: {},
};
export const getFavoriteClub = createAsyncThunk(
  "common/getFavClub",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getFavClub();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getUserDetail = createAsyncThunk(
  "common/getUserDetail",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getUserDetail();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getMember = createAsyncThunk(
  "common/getMember",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getMember();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getTotw = createAsyncThunk(
  "common/getTotw",
  async (rd, thunkAPI) => {
    try {
      console.log("from slice", rd);
      const data = await CommonService.getTotw(rd);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getArticle = createAsyncThunk(
  "common/getArticle",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getArticle();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getArticleDetail = createAsyncThunk(
  "common/getArticleDetail",
  async ({ slug }, thunkAPI) => {
    try {
      const data = await CommonService.getArticleDetail(slug);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getPrize = createAsyncThunk(
  "common/getPrize",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getPrize();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getRule = createAsyncThunk("common/getRule", async (thunkAPI) => {
  try {
    const data = await CommonService.getRule();
    return data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const getFaq = createAsyncThunk("common/getFaq", async (thunkAPI) => {
  try {
    const data = await CommonService.getFaq();
    return data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});
export const getAboutClubText = createAsyncThunk(
  "common/getAboutClubText",
  async (type, thunkAPI) => {
    try {
      const data = await CommonService.getAboutClubText(type);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const tradeHistory = createAsyncThunk(
  "common/tradeHistory",
  async (thunkAPI) => {
    try {
      const data = await CommonService.tradeHistory();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const CommonSlice = createSlice({
  name: "common",
  initialState,
  extraReducers: {
    [getFavoriteClub.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getFavoriteClub.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.favClub = action.payload.data;
    },
    [getFavoriteClub.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getUserDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clubDetail = action.payload.data?.get_club_details;
      state.is_paid = action.payload.data?.is_paid;
      state.get_round = action.payload.data?.get_round;
      state.all_round = action.payload.data?.all_round;
    },
    [getUserDetail.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getMember.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMember.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.memberData = action.payload.data;
    },
    [getMember.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getTotw.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTotw.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.totwData = action.payload.data;
      state.position = action.payload.position;
      state.round_listing = action.payload.round_listing;
    },
    [getTotw.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getArticle.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getArticle.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.articleData = action.payload.data;
    },
    [getArticle.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getArticleDetail.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getArticleDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.articleDetailData = action.payload.data;
      state.image_thumb_image_path = action.payload.image_thumb_image_path;
      state.image_main_image_path = action.payload.image_main_image_path;
    },
    [getArticleDetail.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getPrize.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getPrize.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.prizeData = action.payload.data?.prize_list;
    },
    [getPrize.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getRule.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getRule.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ruleData = action.payload.data;
    },
    [getRule.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getFaq.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getFaq.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.faqData = action.payload.data;
    },
    [getFaq.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getAboutClubText.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAboutClubText.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cmsData = action.payload.data;
    },
    [getAboutClubText.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [tradeHistory.pending]: (state, action) => {
      state.isLoading = true;
    },
    [tradeHistory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.tradeHistoryData = action.payload.data;
      state.gw_trade_in = action.payload.gw_trade_in;
      state.gw_trade_out = action.payload.gw_trade_out;
    },
    [tradeHistory.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

const { reducer, actions } = CommonSlice;

export const { favClub, clubDetail, isLoading } = actions;
export default reducer;
