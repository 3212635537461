import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import CommonService from "../services/common.service";

const initialState = {
  isLoading: false,
  isMoalLoading: false,
  isFollowLoading: false,
  playerProfile: [],
  profile_views: "",
  player_fp: 0,
  player_data: {},
};

export const playerProfile = createAsyncThunk(
  "player/playerProfile",
  async ({ player_id }, thunkAPI) => {
    try {
      const data = await CommonService.playerProfile(player_id);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFixtureModalPoint = createAsyncThunk(
  "stat/getFixtureModalPoint",
  async ({ fixtureId }, thunkAPI) => {
    try {
      const data = await CommonService.getFixtureModalPoint(fixtureId);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const setFollowPlayer = createAsyncThunk(
  "setFollowPlayer",
  async ({ player_id, status }, thunkAPI) => {
    try {
      const data = await CommonService.setFollowPlayer(player_id, status);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const PlayerProfileSlice = createSlice({
  name: "player_profile",
  initialState,
  extraReducers: {
    [playerProfile.pending]: (state, action) => {
      state.isLoading = true;
    },
    [playerProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.player_data = action.payload.player_data;
      state.position = action.payload.position;
      state.player_fp = action.payload.player_fp;
      state.top_player_by_gwk_point = action.payload.top_player_by_gwk_point;
      state.cap_count = action.payload.cap_count;
      state.vc_count = action.payload.vc_count;
      state.player_count = action.payload.player_count;
      state.temp_fs = action.payload.temp_fs;
      state.avgPoints = action.payload.avgPoints;
      state.totalMatches = action.payload.totalMatches;
      state.potm = action.payload.potm;
      state.price_change = action.payload.price_change;
      state.best_score = action.payload.best_score;
      state.worst_score = action.payload.worst_score;
      state.image_path = action.payload.image_path;
      state.last_5_record = action.payload.last_5_record;
      state.profile_views = action.payload.profile_views;
      state.claimProfileData = action.payload.claimProfileData;
    },
    [playerProfile.rejected]: (state, action) => {
      state.isMoalLoading = true;
    },
    [getFixtureModalPoint.pending]: (state, action) => {
      state.isMoalLoading = true;
    },
    [getFixtureModalPoint.fulfilled]: (state, action) => {
      state.isMoalLoading = false;
      state.player_points = action.payload.data;
    },
    [getFixtureModalPoint.rejected]: (state, action) => {
      state.isMoalLoading = true;
    },
    [setFollowPlayer.pending]: (state, action) => {
      state.isFollowLoading = true;
    },
    [setFollowPlayer.fulfilled]: (state, action) => {
      state.isFollowLoading = false;
      state.follow_data = action.payload.data;
    },
    [setFollowPlayer.rejected]: (state, action) => {
      state.isFollowLoading = true;
    },
  },
});

const { reducer } = PlayerProfileSlice;
export default reducer;
