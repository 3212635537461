import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import UserProfile from "../services/user_profile.service";

const initialState = {
  isLoading: false,
  followedPlayerLoading: false,
  userProfile: [],
  followed_player: [],
  user_teams: [],
  team_ranks_arr: [],
  temp_points_arr: [],
  userDetails: [],
  teamDetails: {},
  fantasyStats: {},
  playerStats: {},
  followedPlayer: [],
  followedMemberProfile: [],
  followersMemberProfile: [],
};

export const getUserProfile = createAsyncThunk(
  "stat/getUserProfile",
  async ({ slug }, thunkAPI) => {
    try {
      const data = await UserProfile.getUserProfile(slug);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFollowedPlayer = createAsyncThunk(
  "stat/getFollowedPlayer",
  async ({ slug, pageNumber }, thunkAPI) => {
    try {
      const data = await UserProfile.getFollowedPlayer(slug, pageNumber);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateProfile = createAsyncThunk(
  "stat/updateProfile",
  async ({ userId }, thunkAPI) => {
    try {
      const data = await UserProfile.updateProfile();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const UserProfileSlice = createSlice({
  name: "stat",
  initialState,
  extraReducers: {
    [getUserProfile.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userProfile = action.payload.data?.userProfile;
      state.user_teams = action.payload.data?.user_teams;
      state.team_ranks_arr = action.payload.data?.team_ranks_arr;
      state.temp_points_arr = action.payload.data?.temp_points_arr;
      state.userDetails = action.payload.data?.userDetails;
      state.teamDetails = action.payload.data?.teamDetails;
      state.userGameHistory = action.payload.data?.userGameHistory;
      state.playerGameProfile = action.payload.data?.playerGameProfile;
      state.fantasyStats = action.payload.data?.fantasyStats;
      state.playerStats = action.payload.data?.playerStats;
      state.followedPlayer = action.payload.data?.followedPlayer;
      state.followedMemberProfile = action.payload.data?.followedMemberProfile;
      state.followersMemberProfile =
        action.payload.data?.followersMemberProfile;
    },
    [getUserProfile.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getFollowedPlayer.pending]: (state, action) => {
      state.followedPlayerLoading = true;
    },
    [getFollowedPlayer.fulfilled]: (state, action) => {
      state.followedPlayerLoading = false;
      state.followed_player = action.payload.data;
    },
    [getFollowedPlayer.rejected]: (state, action) => {
      state.followedPlayerLoading = true;
    },
  },
});

const { reducer } = UserProfileSlice;
export default reducer;
