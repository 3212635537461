import React, { useState } from "react";
import "../../assets/css/stripe_css.css";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { useNavigate } from "react-router-dom";
import ToastComponent from "../Common/TaostComponent";
import CommonService from "../../services/common.service";

const PaymentForm = (props) => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [disabledTrue, setDisabledTrue] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      setDisabledTrue(true);
      props.setVisible(false);
      const data = {};
      data.token = result.token;
      data.amount = props.amount;
      data.type = props.type;
      CommonService.savePayment(data)
        .then((res) => {
          if (res.success) {
            setSuccess(true);
            if (res.is_submit) {
              setDisabledTrue(false);
              window.location.href = "/dashboard";
              ToastComponent(
                "Payment confirmed! You're all set to enjoy your game.",
                "success"
              );
            } else {
              ToastComponent(
                "Something went wrong. Please try again.",
                "success"
              );
            }
          } else {
            props.setVisible(false);
            ToastComponent(res.message, "error");
          }
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  };
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#303238",
        fontSize: "16px",
        fontFamily: "sans-serif",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };
  return (
    <>
      {!success ? (
        <form onSubmit={handleSubmit}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <button
            type="submit"
            className="button_custom_class"
            disabled={!stripe}
          >
            Pay
          </button>
        </form>
      ) : (
        <div className="payment-success">
          <h2>Payment successful</h2>
          <h3 className="Thank-you ">Thank you for your payment</h3>
        </div>
      )}
    </>
  );
};

export default PaymentForm;
