import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const GameTour = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < props.tourData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  var settings = {
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [currentItem, setCurrentItem] = useState(null); // Added state to hold the current item

  const openLightbox = (itemIndex, imageIndex) => {
    console.log();
    setSelectedImageIndex(imageIndex);
    setCurrentItem(props.tourData[itemIndex]); // Set the current item based on itemIndex
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  return (
    <>
      <Modal
        show={props.gameTourShow}
        onHide={props.handleGameTourClose}
        size="lg"
      >
        <Modal.Body
          className="py-3 px-3"
          style={{ maxHeight: "600px", overflowY: "auto" }}
        >
          {props.tourData &&
            props.tourData.map(
              (item, key) =>
                key === currentIndex && (
                  <div className="tourstep" key={key}>
                    <div className="blogbox noborder">
                      <div className="vertical-center-3 testimonial">
                        <figure>
                          <Link onClick={(e) => e.preventDefault()}>
                            <img
                              src={`${process.env.REACT_APP_UPLOAD_URL}feature_images/${item?.primary_image}`}
                              alt="img"
                              style={{ objectFit: "fill" }}
                            />
                          </Link>
                        </figure>
                        <>
                          <Slider {...settings}>
                            {item.secondary_image &&
                              JSON.parse(item.secondary_image).map(
                                (value, index) => (
                                  <div
                                    className="clublogo"
                                    key={index}
                                    onClick={() => openLightbox(key, index)}
                                  >
                                    <figure>
                                      <img
                                        src={`${process.env.REACT_APP_UPLOAD_URL}feature_images/${value}`}
                                        alt={`Secondary Imagesd ${index}`}
                                      />
                                    </figure>
                                  </div>
                                )
                              )}
                          </Slider>
                        </>
                      </div>
                    </div>
                    {/* <h2>{item.title}</h2> */}
                    {ReactHtmlParser(item.short_description)}
                  </div>
                )
            )}
          <div className="nextbtn">
            {currentIndex > 0 && (
              <Link className="btn border-btn" onClick={handlePrevious}>
                Previous
              </Link>
            )}
            {currentIndex < props.tourData.length - 1 && (
              <Link className="btn btn-primary" onClick={handleNext}>
                Next
              </Link>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="poweredby">
            Powered By
            <img src="/images/logo.png" alt="img" />
          </div>
          <Link
            className="border-btn"
            onClick={() => props.handleGameTourClose()}
          >
            <i className="fas fa-times"></i>
          </Link>
        </Modal.Footer>
      </Modal>
      {lightboxIsOpen && currentItem && (
        <Lightbox
          mainSrc={`${process.env.REACT_APP_UPLOAD_URL}feature_images/${
            JSON.parse(currentItem.secondary_image)[selectedImageIndex]
          }`}
          nextSrc={`${process.env.REACT_APP_UPLOAD_URL}feature_images/${
            JSON.parse(currentItem.secondary_image)[
              (selectedImageIndex + 1) %
                JSON.parse(currentItem.secondary_image).length
            ]
          }`}
          prevSrc={`${process.env.REACT_APP_UPLOAD_URL}feature_images/${
            JSON.parse(currentItem.secondary_image)[
              (selectedImageIndex +
                JSON.parse(currentItem.secondary_image).length -
                1) %
                JSON.parse(currentItem.secondary_image).length
            ]
          }`}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setSelectedImageIndex(
              (selectedImageIndex +
                JSON.parse(currentItem.secondary_image).length -
                1) %
                JSON.parse(currentItem.secondary_image).length
            )
          }
          onMoveNextRequest={() =>
            setSelectedImageIndex(
              (selectedImageIndex + 1) %
                JSON.parse(currentItem.secondary_image).length
            )
          }
        />
      )}
    </>
  );
};

export default GameTour;
