// userPremiumUtils.js

// Function to check if the user has paid for a specific club
export const isUserPaid = () => {
  // Retrieve userPremium, club_details, and user from local storage
  const userPremium = JSON.parse(localStorage.getItem("userPremimium")) || [];
  const user = JSON.parse(localStorage.getItem("user")) || {};

  // Example usage of club_id and user_id from club_details and user
  const user_id_to_check = user.id;

  // Find the entry in userPremium array for the given club_id and user_id
  const clubEntry = userPremium.find(
    (entry) => entry.user_id === user_id_to_check
  );
  // Check if the entry exists and is_paid is true
  return clubEntry && clubEntry.is_paid;
};
