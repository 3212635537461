import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import CommonService from "../services/common.service";

const initialState = {
  isLoading: false,
  scorcard1: [],
  scorcard2: [],
  fixtureDetails: [],
  fixtureScorcardDetails: [],
  playerScorcards: [],
  getUpdateTime: [],
  potmList: [],
};

export const getMatchScorcard = createAsyncThunk(
  "fixture/getMatchScorcard",
  async ({ fixture_id }, thunkAPI) => {
    try {
      const data = await CommonService.getMatchScorcard(fixture_id);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInCompFixture = createAsyncThunk(
  "getInCompFixture",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getInCompFixture();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const MatchScoreCardSlice = createSlice({
  name: "MatchScorcard",
  initialState,
  extraReducers: {
    [getMatchScorcard.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMatchScorcard.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.fixtureData = action.payload.data;
    },
    [getMatchScorcard.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getMatchScorcard.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.scorcard1 = action.payload.scorcard1;
      state.scorcard2 = action.payload.scorcard2;
      state.fixtureDetails = action.payload.fixtureDetails;
      state.fixtureScorcardDetails = action.payload.fixtureScorcardDetails;
      state.playerScorcards = action.payload.playerScorcards;
      state.getUpdateTime = action.payload.getUpdateTime;
      state.potmList = action.payload.potmList;
      state.isVotingActivate = action.payload.isVotingActivate;
    },
  },
});

const { reducer } = MatchScoreCardSlice;
export default reducer;
