import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import GameStatService from "../services/game_stat.service";

const initialState = {
  isLoading: false,
  gameStatData: [],
};

export const getGameStat = createAsyncThunk(
  "game_stat/getGameStat",
  async (thunkAPI) => {
    try {
      const data = await GameStatService.getGameStat();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const GameStatSlice = createSlice({
  name: "gamestate",
  initialState,
  extraReducers: {
    [getGameStat.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getGameStat.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.playerData = action.payload.data?.playerData;
      state.userTrades = action.payload.data?.userTrades;
    },
    [getGameStat.rejected]: (state, action) => {
      state.isLoading = true;
    },
  },
});

const { reducer } = GameStatSlice;
export default reducer;
