import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import CommonService from "../services/common.service";

const initialState = {
  isLoading: false,
  isPlayerProfileLoading: false,
  playerData: [],
};

export const getPlayer = createAsyncThunk(
  "player/getPlayer",
  async (query, thunkAPI) => {
    try {
      const data = await CommonService.getPlayer(query);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getPlayerList = createAsyncThunk(
  "player/getPlayerList",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getPlayerList();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const playerProfile = createAsyncThunk(
  "player/playerProfile",
  async (thunkAPI) => {
    try {
      const data = await CommonService.playerProfile();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const PlayerSlice = createSlice({
  name: "player",
  initialState,
  extraReducers: {
    [getPlayer.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getPlayer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.playerData = action.payload.data;
      state.position = action.payload.position;
      state.image_path = action.payload.image_path;
    },
    [getPlayer.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [playerProfile.pending]: (state, action) => {
      state.isPlayerProfileLoading = true;
    },
    [playerProfile.fulfilled]: (state, action) => {
      state.isPlayerProfileLoading = false;
      state.playerInfo = action.payload.data;
    },
    [playerProfile.rejected]: (state, action) => {
      state.isPlayerProfileLoading = true;
    },
    [getPlayerList.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getPlayerList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.playerList = action.payload.data;
    },
    [getPlayerList.rejected]: (state, action) => {
      state.isLoading = true;
    },
  },
});

const { reducer } = PlayerSlice;
export default reducer;
