import { combineReducers } from "redux";
import authReducer from "../Slice/auth";
import messageReducer from "../Slice/message";
import commonReducer from "../Slice/CommonSlice";
import myTeamReducer from "../Slice/MyTeamSlice";
import sponsorReducer from "../Slice/SponsorSlice";
import playerReducer from "../Slice/PlayerSlice";
import fixtureReducer from "../Slice/FixtureSlice";
import MatchScoreCardReducer from "../Slice/MatchScoreCardSlice";
import historyReducer from "../Slice/HistorySlice";
import rankReducer from "../Slice/RankSlice";
import statReducer from "../Slice/StatSlice";
import gameStatReducer from "../Slice/GameStatSlice";
import userProfileReducer from "../Slice/UserProfileSlice";
import dashboardReducer from "../Slice/DashboardSlice";
import playerProfileReducer from "../Slice/PlayerProfileSlice";
import myGameSliceReducer from "../Slice/MyGameSlice";
export const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  message: messageReducer,
  myTeam: myTeamReducer,
  sponsor: sponsorReducer,
  player: playerReducer,
  player_profile: playerProfileReducer,
  fixture: fixtureReducer,
  match_scorecard: MatchScoreCardReducer,
  history: historyReducer,
  rank: rankReducer,
  stat: statReducer,
  game_stat: gameStatReducer,
  user_profile: userProfileReducer,
  dashboard: dashboardReducer,
  my_games: myGameSliceReducer,
});
