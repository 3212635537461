import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import RankService from "../services/rank.service";

const initialState = {
  isLoading: false,
  rankData: [],
  round_data: [],
  rankDefault: [],
  isBreakdownLoading: false,
  lockoutRoundDetails: "",
};

export const getRank = createAsyncThunk(
  "rank/getRank",
  async (selectedRoundId, thunkAPI) => {
    try {
      const data = await RankService.getRank(selectedRoundId);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getRankByGameweek = createAsyncThunk(
  "rank/getRankByGameweek",
  async ({ roundId }, thunkAPI) => {
    try {
      const data = await RankService.getRankByGameweek(roundId);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getRoundBreakDown = createAsyncThunk(
  "rank/getRoundBreakDown",
  async ({ teamId }, thunkAPI) => {
    try {
      const data = await RankService.getRoundBreakDown(teamId);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const RankSlice = createSlice({
  name: "rank",
  initialState,
  extraReducers: {
    [getRank.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getRank.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.rankDefault = action.payload.data?.user_teams;
      state.team_ranks_arr = action.payload.data?.team_ranks_arr;
      state.temp_points_arr = action.payload.data?.temp_points_arr;
      state.lockoutDetail = action.payload.data?.lockoutRoundDetails;
      state.image_path = action.payload.image_path;
    },
    [getRank.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getRankByGameweek.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getRankByGameweek.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.round_data = action.payload.data?.round_data;
      state.round_listing = action.payload.data?.round_listing;
    },
    [getRankByGameweek.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getRoundBreakDown.pending]: (state, action) => {
      state.isBreakdownLoading = true;
    },
    [getRoundBreakDown.fulfilled]: (state, action) => {
      state.isBreakdownLoading = false;
      state.roundBreakDownData = action.payload.data;
    },
    [getRoundBreakDown.rejected]: (state, action) => {
      state.isBreakdownLoading = true;
    },
  },
});

const { reducer } = RankSlice;
export default reducer;
