import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const agent = axios.create({
  baseURL: API_URL,
});

agent.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  const clubData = JSON.parse(localStorage.getItem("club_details"));
  const location = window.location.pathname;
  const urlWithoutSlashes = location.substring(1);
  if (!token) {
    window.location.href = "/login";
    return config;
  }
  const clubId = clubData ? clubData.id : 0;
  config = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      "x-game-info": `${clubId}`,
      "x-page-name": `${urlWithoutSlashes}`,
      "Content-Type": "application/json",
    },
  };

  return config;
});

agent.interceptors.response.use(
  function (response) {
    if (response.data.status === false) {
      localStorage.clear();
      window.location.href = "/login";
    }
    if (response.data.status === 501) {
      console.log("501 error getting");
      //window.location.href = "/my-games";
    }
    return response;
  },
  function (error) {
    console.log("error", error);
    if (error.response.data.message === "Unauthenticated.") {
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    }
    return Promise.reject(error.response.data);
  }
);

export { agent };
