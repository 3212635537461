import axios from "axios";
import { agent } from "../utils/agent";
const API_URL = process.env.REACT_APP_API_URL;

const getFavClub = async () => {
  const response = await agent.get(API_URL + "get-fav-club");
  return response.data;
};

const getPlayingEleven = async (clubId) => {
  const response = await agent.get(API_URL + `get-playing-eleven`);
  return response.data;
};
const getUserDetail = async () => {
  const response = await agent.get(API_URL + "get-user-club");
  return response.data;
};

const getSponsor = async (club_id) => {
  const response = await agent.get(API_URL + "sponsors");
  return response.data;
};

const getPlayer = async (query) => {
  const response = await agent.get(`${API_URL}players`);
  return response.data;
};
const gamePlayer = async (clubId) => {
  const response = await axios.get(`${API_URL}gamePlayer?club_id=${clubId}`);
  return response.data;
};
const gamePlayerProfile = async (player_id) => {
  const response = await axios.get(`${API_URL}gamePlayerProfile/${player_id}`);
  return response.data;
};
const getPlayerForSearching = async (query) => {
  const response = await agent.get(`${API_URL}players`);
  return response.data;
};
const getPlayerCardDetail = async (playerId) => {
  const response = await axios.get(
    `${API_URL}get-player-card-details/${playerId}`
  );
  return response.data;
};
const getPlayerList = async (club_id) => {
  const response = await agent.get(API_URL + "player-list");
  return response.data;
};
const getFixture = async (roundId) => {
  const response = await agent.get(API_URL + `fixtures?round=${roundId}`);
  return response.data;
};
const getInCompFixture = async (club_id) => {
  const response = await agent.get(API_URL + "inprogress-completed-fixtures");
  return response.data;
};
const getMember = async (club_id) => {
  const response = await agent.get(API_URL + "game-members");
  return response.data;
};
const getTotw = async (rd = "") => {
  const response = await agent.get(`${API_URL}team-of-the-week?rd=${rd}`);
  return response.data;
};
const getArticle = async (club_id) => {
  const response = await agent.get(API_URL + "articles");
  return response.data;
};
const getArticleDetail = async (slug) => {
  const response = await agent.get(API_URL + "article-details/" + slug);
  return response.data;
};
const getPrize = async (club_id) => {
  const response = await agent.get(API_URL + "prize");
  return response.data;
};

const getRule = async (club_id) => {
  const response = await axios.get(API_URL + "rules");
  return response.data;
};
const getFaq = async (club_id) => {
  const response = await axios.get(API_URL + "faqs");
  return response.data;
};
const getClubDetails = async (club_id) => {
  const response = await axios.get(`${API_URL}getClubDetails/${club_id}`);
  return response.data;
};
const getAboutClubText = async (type) => {
  const response = await axios.get(API_URL + "getAboutClubText/" + type);
  return response.data;
};
const playerProfile = async (player_id) => {
  const response = await agent.get(`${API_URL}player-profile/${player_id}`);
  return response.data;
};
const playerChart = async (player_id) => {
  const response = await agent.get(`${API_URL}playerChart/${player_id}`);
  return response.data;
};
const gameChart = async (player_id) => {
  const response = await agent.get(`${API_URL}gameChart`);
  return response.data;
};

const guestGameChart = async (clubId) => {
  const response = await axios.get(
    `${API_URL}guestGameChart?club_id=${clubId}`
  );
  return response.data;
};

const guestGetGameBarGraph = async (clubId) => {
  const response = await axios.get(
    `${API_URL}guestGetGameBarGraph?club_id=${clubId}`
  );
  return response.data;
};
const memberGameChart = async (club_id) => {
  const response = await axios.get(`${API_URL}memberGameChart`);
  return response.data;
};
const memberGetGameBarGraph = async (player_id) => {
  const response = await axios.get(`${API_URL}memberGetGameBarGraph`);
  return response.data;
};
const getGameBarGraph = async (player_id) => {
  const response = await agent.get(`${API_URL}getGameBarGraph`);
  return response.data;
};

const getPlayerGameBarGraph = async (player_id) => {
  const response = await agent.get(
    `${API_URL}getPlayerGameBarGraph/${player_id}`
  );
  return response.data;
};

const getFixtureModalPoint = async (fixtureId) => {
  const response = await agent.get(`${API_URL}getPlayerData/${fixtureId}`);
  return response.data;
};
const setFollowPlayer = async (player_id, status) => {
  const response = await agent.get(
    `${API_URL}player-follow/${player_id}/${status}`
  );
  return response.data;
};
const setFollowMemberProfile = async (profileId, status) => {
  const response = await agent.get(
    `${API_URL}member-profile-follow/${profileId}/${status}`
  );
  return response.data;
};

const getMatchScorcard = async (fixture_id) => {
  const response = await agent.get(`${API_URL}match-scorcard/${fixture_id}`);
  return response.data;
};
const userAllGames = async () => {
  const response = await agent.get(`${API_URL}user-all-games`);
  return response.data;
};
const adminGameListing = async () => {
  const response = await agent.get(`${API_URL}adminGameListing`);
  return response.data;
};
const getUserNotification = async (currentPage = "") => {
  const response = await agent.get(
    `${API_URL}getUserNotification?page=${currentPage}`
  );
  return response.data;
};
const getBlog = async () => {
  const response = await agent.get(`${API_URL}getBlog`);
  return response.data;
};
const getBlogDetails = async (slug) => {
  const response = await agent.get(`${API_URL}getBlogDetails/${slug}`);
  return response.data;
};
const tradeHistory = async (slug) => {
  const response = await agent.get(`${API_URL}tradeHistory`);
  return response.data;
};
const getGameAccount = async (clubName = "") => {
  const response = await agent.get(
    `${API_URL}getGameAccount?club_name=${clubName}`
  );
  return response.data;
};
const getGradeList = async (clubName = "") => {
  const response = await agent.get(`${API_URL}getGradeList`);
  return response.data;
};

const getGameGradeList = async (gameId = "") => {
  const response = await axios.get(
    `${API_URL}getGameGradeList?game_id=${gameId}`
  );
  return response.data;
};

const getGradePointSystem = async (gradeId) => {
  const response = await axios.get(`${API_URL}getGradePointSystem/${gradeId}`);
  return response.data;
};
const getAccountGame = async (clubId = "") => {
  const response = await agent.get(
    `${API_URL}getAccountGame?club_id=${clubId}`
  );
  return response.data;
};
const gameSignup = async (clubId) => {
  const response = await axios.get(`${API_URL}gameSignup`);
  return response.data;
};
const userSignupForm = async (gameName) => {
  const response = await axios.get(
    `${API_URL}userSignupForm?accountName=${gameName}`
  );
  return response.data;
};
const getStateFromCountryId = async (countryId) => {
  const response = await axios.get(
    `${API_URL}getStateFromCountryId/${countryId}`
  );
  return response.data;
};
const saveGameSignup = async (formValue) => {
  const response = await axios.post(`${API_URL}saveGameSignup`, formValue);
  return response.data;
};
const saveFixtureVoting = async (data) => {
  const response = await agent.post(`${API_URL}saveFixtureVoting`, data);
  return response.data;
};
const userAllGame = async (accountName = "") => {
  const location = window.location.pathname;
  const urlWithoutSlashes = location.substring(1);
  const config = {
    headers: {
      "x-page-name": urlWithoutSlashes,
    },
  };
  const response = await axios.get(
    `${API_URL}user-all-games?account_name=${accountName}`,
    config
  );
  return response.data;
};
const addGameAccount = async (accountId) => {
  const response = await agent.get(`${API_URL}addGameAccount/${accountId}`);
  return response.data;
};
const getUpdateProfileData = async () => {
  const response = await agent.get(`${API_URL}getUpdateProfileData`);
  return response.data;
};
const getLoginUserData = async () => {
  const response = await agent.get(`${API_URL}getLoginUserData`);
  return response.data;
};
const joinGame = async () => {
  const response = await axios.get(`${API_URL}joinGame`);
  return response.data;
};
const saveUpdateProfile = async (formData) => {
  const response = await agent.post(`${API_URL}saveUpdateProfile`, formData);
  return response.data;
};
const addUserFavoriteGame = async (data) => {
  const response = await agent.post(`${API_URL}addUserFavoriteGame`, data);
  return response.data;
};
const setGameUser = async (data) => {
  const response = await agent.post(`${API_URL}setGameUser`, data);
  return response.data;
};
const checkGameCode = async (data) => {
  const response = await agent.post(`${API_URL}checkGameCode`, data);
  return response.data;
};
const getGameDetails = async (gameId) => {
  const response = await agent.get(`${API_URL}getGameDetails/${gameId}`);
  return response.data;
};
const markAsPaidOrUnPaid = async (userId, status, type, gameId) => {
  const response = await agent.get(
    `${API_URL}users/update-paid-status/${userId}/${status}/${type}/${gameId}`
  );
  return response.data;
};
const savePayment = async (data) => {
  const response = await agent.post(API_URL + "savePayment", data);
  return response.data;
};
const makeDefaultOrDeleteMyGame = async (data) => {
  const response = await agent.post(
    API_URL + "makeDefaultOrDeleteMyGame",
    data
  );
  return response.data;
};
const getIsDefaultAccountId = async (data) => {
  const response = await agent.get(API_URL + "getIsDefaultAccountId");
  return response.data;
};
const getFixturePlayerList = async (fixtureId) => {
  const response = await agent.get(
    `${API_URL}getFixturePlayerList/${fixtureId}`
  );
  return response.data;
};
const getTimelineData = async (type) => {
  const response = await agent.get(`${API_URL}getTimelineData/${type}`);
  return response.data;
};
const saveLikeDislike = async (data) => {
  const response = await agent.post(API_URL + "saveLikeDislike", data);
  return response.data;
};
const requestClaimPlayerProfile = async (data) => {
  const response = await agent.post(
    API_URL + "requestClaimPlayerProfile",
    data
  );
  return response.data;
};
const removeFollower = async (data) => {
  const response = await agent.post(API_URL + "removeFollower", data);
  return response.data;
};
const bracketBattle = async () => {
  const response = await agent.get(`${API_URL}bracketBattle`);
  return response.data;
};

const overallPlayerVoting = async () => {
  const response = await agent.get(`${API_URL}overallPlayerVoting`);
  return response.data;
};
const getTeamList = async () => {
  const response = await agent.get(`${API_URL}getTeamList`);
  return response.data;
};
const showTeamOwner = async () => {
  const response = await agent.get(`${API_URL}showTeamOwner`);
  return response.data;
};

const getPlayerbadge = async (player_id) => {
  const response = await agent.get(`${API_URL}getPlayerbadge/${player_id}`);
  return response.data;
};
const getGamePlayerBadge = async (player_id) => {
  const response = await axios.get(`${API_URL}getPlayerbadge/${player_id}`);
  return response.data;
};

const activateCaptonCard = async (data) => {
  const response = await agent.post(`${API_URL}activateCaptonCard`, data);
  return response.data;
};
const getUserImage = async (data) => {
  const response = await agent.post(`${API_URL}getUserImageURL`, data);
  return response.data;
};

const getMembershipData = async () => {
  const response = await agent.get(`${API_URL}getMembershipData`);
  return response.data;
};
const refundMembership = async () => {
  const response = await agent.get(`${API_URL}refundMembership`);
  return response.data;
};
const getPageCount = async (pageName = "") => {
  const response = await agent.get(
    `${API_URL}getPageCount?page_name=${pageName}`
  );
  return response.data;
};
const getFeaturePageData = async (pageType) => {
  const response = await axios.get(
    `${API_URL}getFeaturePageData?pageType=${pageType}`
  );
  return response.data;
};
const getShowCasePageData = async () => {
  const response = await axios.get(`${API_URL}getShowCasePageData`);
  return response.data;
};

const getFeatureDetail = async (id) => {
  const response = await axios.get(`${API_URL}getFeatureDetail/${id}`);
  return response.data;
};
const getRoundListingForGuest = async (clubId) => {
  const response = await axios.get(
    `${API_URL}getRoundListingForGuest/${clubId}`
  );
  return response.data;
};

const getFantasyScoreTotal = async () => {
  const response = await agent.get(API_URL + "getFantasyScoreTotal");
  return response.data;
};
const guestGetFantasyScoreTotal = async (club_id) => {
  const response = await axios.get(
    API_URL + `guestGetFantasyScoreTotal?club_id=${club_id}`
  );
  return response.data;
};
const getHubFantasyScoreTotal = async (clubId) => {
  const response = await axios.get(
    API_URL + `getHubFantasyScoreTotal?account_name=${clubId}`
  );
  return response.data;
};

const CommonService = {
  saveLikeDislike,
  getTimelineData,
  markAsPaidOrUnPaid,
  saveFixtureVoting,
  getFixturePlayerList,
  getIsDefaultAccountId,
  makeDefaultOrDeleteMyGame,
  getLoginUserData,
  savePayment,
  getFavClub,
  getUserDetail,
  getPlayingEleven,
  getSponsor,
  getPlayer,
  getFixture,
  getInCompFixture,
  getMember,
  getTotw,
  getArticle,
  getPrize,
  getRule,
  getFaq,
  playerProfile,
  getFixtureModalPoint,
  setFollowPlayer,
  getPlayerList,
  getMatchScorcard,
  getArticleDetail,
  userAllGames,
  adminGameListing,
  getUserNotification,
  getBlog,
  getBlogDetails,
  tradeHistory,
  getPlayerCardDetail,
  getGameAccount,
  getAccountGame,
  gameSignup,
  getStateFromCountryId,
  saveGameSignup,
  userAllGame,
  userSignupForm,
  addGameAccount,
  getUpdateProfileData,
  saveUpdateProfile,
  joinGame,
  addUserFavoriteGame,
  setGameUser,
  getGameDetails,
  checkGameCode,
  getAboutClubText,
  getPlayerForSearching,
  getGradeList,
  getGradePointSystem,
  bracketBattle,
  requestClaimPlayerProfile,
  setFollowMemberProfile,
  removeFollower,
  overallPlayerVoting,
  getTeamList,
  playerChart,
  gameChart,
  guestGameChart,
  showTeamOwner,
  getGameBarGraph,
  getPlayerGameBarGraph,
  getPlayerbadge,
  activateCaptonCard,
  getUserImage,
  getMembershipData,
  refundMembership,
  getFeaturePageData,
  getFeatureDetail,
  getShowCasePageData,
  getRoundListingForGuest,
  getPageCount,
  gamePlayer,
  gamePlayerProfile,
  getGamePlayerBadge,
  getGameGradeList,
  getClubDetails,
  memberGameChart,
  memberGetGameBarGraph,
  getFantasyScoreTotal,
  getHubFantasyScoreTotal,
  guestGetGameBarGraph,
  guestGetFantasyScoreTotal,
};

export default CommonService;
