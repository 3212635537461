import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import CommonService from "../services/common.service";

const initialState = {
  isLoading: false,
  sponsorData: [],
};

export const getSponsor = createAsyncThunk(
  "sponsor/getSponsor",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getSponsor();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const sponsorSlice = createSlice({
  name: "sponsor",
  initialState,
  extraReducers: {
    [getSponsor.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getSponsor.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.sponsorData = action.payload.data;
    },
    [getSponsor.rejected]: (state, action) => {
      state.isLoading = true;
    },
  },
});

const { reducer } = sponsorSlice;
export default reducer;
