import { agent } from "../utils/agent";
const API_URL = process.env.REACT_APP_API_URL;

const getStat = async (rd = "", team = "", currentPage = "", perPage = "") => {
  const response = await agent.get(
    API_URL +
      `stats?rd=${rd}&team=${team}&page=${currentPage}&perPage=${currentPage}`
  );
  return response.data;
};

const getRDPlayerPoint = async (playerId) => {
  const response = await agent.get(
    API_URL + `get-round-player-points/${playerId}`
  );
  return response.data;
};

const History = {
  getStat,
  getRDPlayerPoint,
};

export default History;
