import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import MyGameService from "../services/my_game.service";
import CommonService from "../services/common.service";

const initialState = {
  isLoading: false,
  gameLoading: false,
  gameData: [],
  accontIds: [],
  default_account_game: [],
  defaultAccIds: [],
  multiPlayerInfo: [],
  branding: {},
  defaultGameSelected: 0,
};

export const getData = createAsyncThunk(
  "my_games/getData",
  async (gameData, thunkAPI) => {
    try {
      const data = await MyGameService.getGameDetail(gameData);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAccountGame = createAsyncThunk(
  "my_games/getAccountGame",
  async (gameId, thunkAPI) => {
    try {
      const data = await CommonService.getAccountGame(gameId);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const MyGameSlice = createSlice({
  name: "my_games",
  initialState,
  extraReducers: {
    [getData.pending]: (state, action) => {
      state.gameLoading = true;
    },
    [getData.fulfilled]: (state, action) => {
      state.gameLoading = false;
      state.gameData = action.payload.data;
      state.gameAccountDropdown = action.payload.gameAccountDropdown;
      state.accontIds = action.payload.accontIds;
      state.default_account_game = action.payload.default_account_game;
    },
    [getData.rejected]: (state, action) => {
      state.gameLoading = false;
    },
    [getAccountGame.pending]: (state, action) => {
      state.gameLoading = true;
    },
    [getAccountGame.fulfilled]: (state, action) => {
      state.gameLoading = false;
      state.gameList = action.payload.data;
      state.defaultAccIds = action.payload.default_account_ids;
      state.multiPlayerInfo = action.payload.multiPlayerData;
      state.branding = action.payload.branding;
      state.club_details = action.payload.club_details;
      state.defaultGameSelected = action.payload.defaultGameSelected;
    },
    [getAccountGame.rejected]: (state, action) => {
      state.gameLoading = false;
    },
  },
});

const { reducer } = MyGameSlice;
export default reducer;
