import { agent } from "../utils/agent";
const API_URL = process.env.REACT_APP_API_URL;

const getGameStat = async () => {
  const response = await agent.get(API_URL + `game-stats`);
  return response.data;
};

const History = {
  getGameStat,
};

export default History;
