import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import HistoryService from "../services/history.service";

const initialState = { isLoading: false, historyData: [] };

export const getHistory = createAsyncThunk(
  "history/getHistory",
  async (thunkAPI) => {
    try {
      const data = await HistoryService.getHistory();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const HistorySlice = createSlice({
  name: "history",
  initialState,
  extraReducers: {
    [getHistory.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getHistory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.historyData = action.payload.data;
    },
    [getHistory.rejected]: (state, action) => {
      state.isLoading = true;
    },
  },
});

const { reducer } = HistorySlice;
export default reducer;
